<template>
  <div>
    
    <div class= "col-md-12">
      <CCard>
        <CCardHeader>
          <strong>{{graphData.heading}}</strong>
        </CCardHeader>
        <CChartLine
          :datasets="defaultDatasets(graphData.data)"
          :options="defaultOptions(graphData.data)"
          :labels="graphData.label"
          style="height: 300px; margin-top: 40px"
      />
      </CCard>
    </div>
  </div>
</template>


<script>
import { CChartLine } from "@coreui/vue-chartjs";
import { getStyle, hexToRgba } from "@coreui/utils/src";

// import MainChartExample from "../../charts/GraphChartExample";
export default {
  name: 'AdminGraphDetails',
   components: { CChartLine }, 
    props: ["graph"],
   data() {
        return {
          graphData : {
            data: [],
            label: [],
            heading: ""
          }
        };
    },
    methods: {
        getMax(data) {
            let maxVal = Math.max.apply(Math, data);
            return maxVal;
        },
    },
   computed: {
        defaultDatasets() {
            const brandSuccess = getStyle("success2") || "#4dbd74";
            const brandInfo = getStyle("info") || "#20a8d8";

            return (data) => [
                {
                    label: "Value: ",
                    backgroundColor: hexToRgba(brandInfo, 10),
                    borderColor: brandSuccess,
                    pointHoverBackgroundColor: brandSuccess,
                    borderWidth: 2,
                    data: data,
                },
            ];
        },

        defaultOptions() {
            return (data) => ({
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    xAxes: [
                        {
                            gridLines: {
                                drawOnChartArea: false,
                            },
                        },
                    ],
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                maxTicksLimit: 5,
                                stepSize: Math.ceil(10 / 5),
                                max: this.getMax(data),
                            },
                            gridLines: {
                                display: true,
                            },
                        },
                    ],
                },
                elements: {
                    point: {
                        radius: 0,
                        hitRadius: 10,
                        hoverRadius: 4,
                        hoverBorderWidth: 3,
                    },
                },
            });
        },
    }, 
    mounted() {
        if (this.graph) {
            this.graphData = this.graph;
        }
    }, 
}
</script>